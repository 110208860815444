import img from '@/assets/官网首页配图/第二页动图.gif';
<template>
  <div class="A90">
    <div class="banner">
      <div class="textBox">
        <div class="title">Agility A-90</div>
        <div class="smallTitle">2D Desktop Barcode Scanner</div>
        <div class="iconTextBox">
          <div class="left">
            <img src="../../../assets/9520网站配图/图标/路径 5.png" alt="" />
            Barcode
          </div>
          <div class="right">
            <img src="../../../assets/9520网站配图/图标/路径 4.png" alt="" />
            QR/PDF41/DPM
          </div>
        </div>
      </div>
    </div>
    <div class="video">
      <video
        src="../../../assets/A90配图/Compax_A_90_Video.mp4"
        muted
        autoplay
        loop
        webkit-playsinline
        playsinline
      ></video>
    </div>
    <div class="Features">
      <div class="top">
        <div class="title">A-90 Features</div>
        <ul>
          <li>
            <i></i>
            <div>Equipped with new decoding chip & million-pixel camera</div>
          </li>
          <li>
            <i></i>
            <div>
              Advanced decoding algorithm, 2m/s tolerance, and wide reading
              angle for seamless high-speed scanning.
            </div>
          </li>
          <li>
            <i></i>
            <div>
              Detachable base & embedded shell for easy counter attachment.
            </div>
          </li>
          <li>
            <i></i>Supports 1D & toggle 2D barcode scanning via touch buttons
            for diverse scenarios.
          </li>
        </ul>
      </div>
      <div class="bottom">
        <img src="../../../assets/A90配图/移动端/第二页.png" alt="" />
      </div>
    </div>
    <div class="product">
      <img src="../../../assets/A90配图/移动端/标注.png" alt="" />
    </div>
    <div class="Agility">
      <div>
        <div class="titleBox">
          <div class="title">Agility A-90</div>
          <!-- <div class="title">Agility 9520</div> -->
        </div>
        <div class="itemBox">
          <div class="one">
            <div class="itemA">
              <div class="title">Print contrast ratio</div>
              <div class="text">≥20%</div>
            </div>
            <div class="itemA">
              <div class="title">Light Source</div>
              <div class="text">LED Red light</div>
              <div class="text">(Osram from Germany)</div>
            </div>
            <div class="itemE">
              <div class="title">Base</div>
              <div class="text">Detachable</div>
            </div>
            <div class="itemA">
              <div class="title">Image Sensor</div>
              <div class="text">1.3 Million pixels Global Shutter</div>
            </div>
            <div class="itemA">
              <div class="title">Ambient light</div>
              <div class="text">0 -100,000 lux</div>
            </div>
            <div class="itemA">
              <div class="title">Resolution(maximum)</div>
              <div class="text">0.102mm / 4mils</div>
            </div>
            <div class="itemA">
              <div class="title">Motion tolerance</div>
              <div class="text">2M/sec</div>
            </div>
            <div class="itemA">
              <div class="title">Reading Indicators</div>
              <div class="text">LED light&Buzzer</div>
              <div class="text">(adjustable tone and volume)</div>
            </div>
            <div class="itemA">
              <div class="title">IESD protection</div>
              <div class="text">Touch 8 kV; Air 15kV</div>
            </div>
            <div class="itemA">
              <div class="title">Humidity(non-condensing)</div>
              <div class="text">5 - 95%</div>
            </div>
            <div class="itemA">
              <div class="title">Input Voltage</div>
              <div class="text">5 VDC (+/- 10%)</div>
            </div>
            <div class="itemB">
              <div class="title">Current</div>
              <div class="text">Working current (Standard):</div>
              <div class="text">&lt; 430 mA</div>
              <div class="text">Standby current (Standard):</div>
              <div class="text">&lt; 220 mA</div>
            </div>
            <div class="itemB">
              <div class="title">Reading Angle</div>
              <div class="text">Pitch: 0-360° ;</div>
              <div class="text">Roll(Tilt): ± 60° ;</div>
              <div class="text">Skew (Yaw): ± 60°</div>
            </div>
            <div class="itemC">
              <div class="title">Decoding Capability</div>
              <div class="text">
                1D: All standard 1D codes, including GS1 DataBar™ linear code
                can be automatically distinguished and decoded.
              </div>
              <div class="text">
                2D: Aztec Code; Data Matrix; MaxiCode; QRCode; HANXIN; MicroQR
                Code Stack code: GS1 DataBar Expanded Stacked; GS1 DataBar
                Stacked; GS1 DataBar Stacked Omnidirectional; GS1 DataBar
                Composite; MicroPDF417; PDF417.
              </div>
            </div>
            <div class="itemD">
              <div class="title">Typical depth of Field</div>
              <div class="table">
                <div class="item">
                  <div class="text">Precision</div>
                  <div class="text">5mil</div>
                  <div class="text">5mil</div>
                  <div class="text">13.34mil</div>
                  <div class="text">10mil</div>
                  <div class="text">10mil</div>
                  <div class="text">20mil</div>
                  <div class="text">20mil</div>
                </div>
                <div class="item">
                  <div class="text">Barcode</div>
                  <div class="text">CODE128</div>
                  <div class="text">EAN-13</div>
                  <div class="text">EAN-13</div>
                  <div class="text">QR</div>
                  <div class="text">PDF417</div>
                  <div class="text">QR</div>
                  <div class="text">DM</div>
                </div>
                <div class="item">
                  <div class="text">Depth of Field</div>
                  <div class="text">0mm—90mm</div>
                  <div class="text">0mm—90mm</div>
                  <div class="text">0mm—280mm</div>
                  <div class="text">0mm—110mm</div>
                  <div class="text">0mm—260mm</div>
                  <div class="text">0mm—200mm</div>
                  <div class="text">5mm—110mm</div>
                </div>
              </div>
            </div>
            <div class="itemE">
              <div class="title">Temperature</div>
              <div class="text">Operation: -10 to 50 °C</div>
              <div class="text">Storage/Transport: -20 to 65 °C</div>
            </div>
            <div class="itemF">
              <div class="title">Dimensions</div>
              <div class="rightImg"></div>
            </div>
          </div>
        </div>
        <!-- <div class="downloads">
          <div class="box">
            <div class="itemA">
              <div class="title">9520</div>
              <div class="text">PDF(1.08MB)</div>
              <div class="icon"><i class="el-icon-bottom"></i></div>
            </div>
            <div class="itemB">
              <div class="title">9520 User Manual</div>
              <div class="text">PDF(1.08MB)</div>
              <div class="icon"><i class="el-icon-bottom"></i></div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.A90 {
  .banner {
    position: relative;
    height: 400px;
    background: url('../../../assets/A90配图/移动端/首页.png.png') no-repeat
      center;
    background-size: cover;
    .textBox {
      flex-direction: column;
      width: 100%;
      display: flex;
      justify-content: center;
      .title {
        text-align: center;
        color: rgba(0, 92, 255, 1);
        font-size: 26px;
        font-weight: 700;
        line-height: 37.65px;
      }
      .smallTitle {
        text-align: center;
        margin: 0px 0px 11px 0px;
        color: #fff;
        font-size: 12px;
        line-height: 17.38px;
      }
      .iconTextBox {
        margin: 0px auto;
        img {
          margin-right: 5px;
        }
        .left {
          display: flex;
          align-items: center;
          img {
            width: 24.77px;
            height: 17.93px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          img {
            width: 19.17px;
            height: 18px;
          }
        }
        display: flex;
        justify-content: space-between;
        width: 190px;
        display: flex;
        color: #fff;
        font-size: 10px;
        line-height: 14.48px;
      }
    }
  }
  .video {
    height: 219.42px;
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
      object-position: 50% 60%;
    }
  }
  .Features {
    background: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
      .title {
        color: #fff;
        text-align: center;
        margin: 7px 0px;
        font-size: 10px;
        font-weight: 600;
        line-height: 40px;
      }
      ul {
        display: flex;
        align-items: center;
        flex-direction: column;
        li {
          color: #fff;
          width: 265px;
          display: flex;
          margin-bottom: 19px;
          line-height: 11.58px;
          font-size: 8px;
          i {
            margin: 0px 7px 0px 0px;
            width: 0;
            height: 0;
            font-size: 0;
            line-height: 0;
            border-top: 6px solid transparent;
            border-left: 12px solid rgba(0, 92, 255, 1);
            border-bottom: 6px solid transparent;
          }
        }
      }
    }
    .bottom {
      margin-top: 10px;
      width: 183px;
      height: 201px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .product {
    padding-top: 109px;
    display: flex;
    justify-content: center;
    background: #000;
    img {
      width: 317px;
    }
  }
  .Agility {
    background: rgba(0, 0, 0, 1);
    padding: 27.46px 15px 0px;
    .titleBox {
      position: relative;
      font-weight: 500;
      line-height: 28.96px;
      color: rgba(0, 92, 255, 1);
      font-size: 20px;
      padding-bottom: 6px;
      border-bottom: 2px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -2px;
        content: '';
        width: 175px;
        height: 0px;
        border-bottom: 2px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      padding: 0px 6px;
      .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
      }
      .text {
        font-size: 10px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 23px;
        padding: 7px 0px 0px 10px;
        width: 168px;
        height: 80px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 23px;
        padding: 7px 0px 0px 10px;
        width: 168px;
        height: 140px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(27, 32, 48, 1);
      }
      .itemC {
        margin-top: 23px;
        padding: 7px 0px 0px 10px;
        width: 347px;
        height: 160px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(27, 32, 48, 1);
      }
      .itemD {
        margin-top: 23px;
        padding: 7px 0px 0px 10px;
        width: 347px;
        height: 220px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(27, 32, 48, 1);
        .table {
          display: flex;
          .item {
            margin-right: 22px;
          }
        }
      }
      .itemE {
        margin-top: 23px;
        padding: 7px 0px 0px 10px;
        width: 347px;
        height: 91px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(27, 32, 48, 1);
      }
      .itemF {
        position: relative;
        margin-top: 23px;
        padding: 7px 0px 0px 10px;
        width: 347px;
        height: 270px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(0, 92, 255, 0.5);
        .rightImg {
          color: rgba(255, 255, 255, 1);
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          top: 51px;
          left: 81px;
          width: 233px;
          height: 200px;
          background: url('../../../assets/A90配图/移动端/尺寸.png') no-repeat
            center;
          background-size: cover;
        }
      }
      .one {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    .downloads {
      margin-top: 150px;
      .box {
        padding: 0px 10px;
        display: flex;
        .title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 5px;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(128, 128, 128, 1);
        }
        .itemA {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 220px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
        .itemB {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 251px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
      }
    }
  }
}
</style>
